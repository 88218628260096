@font-face {
  font-family: "Graphik";
  src: url("./assets/fonts/graphik/Graphik-Semibold.eot");
  src: url("./assets/fonts/graphik/Graphik-Semibold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/graphik/Graphik-Semibold.svg#Graphik-Semibold")
      format("svg"),
    url("./assets/fonts/graphik/Graphik-Semibold.ttf") format("truetype"),
    url("./assets/fonts/graphik/Graphik-Semibold.woff") format("woff"),
    url("./assets/fonts/graphik/Graphik-Semibold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Graphik";
  src: url("./assets/fonts/graphik/Graphik-Regular.eot");
  src: url("./assets/fonts/graphik/Graphik-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/graphik/Graphik-Regular.svg#Graphik-Semibold")
      format("svg"),
    url("./assets/fonts/graphik/Graphik-Regular.ttf") format("truetype"),
    url("./assets/fonts/graphik/Graphik-Regular.woff") format("woff"),
    url("./assets/fonts/graphik/Graphik-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("./assets/fonts/graphik/Graphik-Light.eot");
  src: url("./assets/fonts/graphik/Graphik-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/graphik/Graphik-Light.svg#Graphik-Semibold")
      format("svg"),
    url("./assets/fonts/graphik/Graphik-Light.ttf") format("truetype"),
    url("./assets/fonts/graphik/Graphik-Light.woff") format("woff"),
    url("./assets/fonts/graphik/Graphik-Light.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

body {
  scroll-behavior: smooth-scroll;
  margin: 0;
  font-family: Graphik;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.nav-toggle {
  position: absolute;
  padding: 10px;
  background: transparent;
  border: 1px solid transparent;
  margin: 7px 0;
  top: 0;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.nav-toggle:focus {
  outline-width: 0;
}

.nav-toggle [class*="bar-"] {
  background: #fff;
  display: block;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease all;
  transition: 0.2s ease all;

  border-radius: 8px;
  height: 2px;
  width: 43px;
  margin-bottom: 5px;
}

.nav-toggle .bar-bot {
  margin-bottom: 0;
}

.opened .bar-top {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 37% -40%;
  transform-origin: 37% -40%;
}
.opened .bar-mid {
  opacity: 0;
}
.opened .bar-bot {
  -webkit-transform: rotate(45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 15% 95%;
  transform-origin: 15% 95%;
}

.menu_drop {
  background-color: #000000d9;
  width: 100%;

  z-index: 20;
  display: flex;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: opacity 0.5s ease-in-out 0.3s, visibility 0.1s ease-in-out;
}
.menu_drop ul {
  padding-left: 0;
}

.menu_drop.open {
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  height: 100vh;
}

.menu_drop.open li {
  font-size: 23px;
  text-transform: capitalize;
  padding: 10px 0;
  display: block;
  color: #fff;
  text-align: left;
  font-weight: 100;
}

.main.menu-wrapper {
  padding: 45px 120px 45px 10px;
  position: relative;
  width: 15%;
  transition: transform 1s ease-in-out;
}

.submenu.first,
.submenu.second {
  visibility: hidden;
  opacity: 0;
  left: 74px;
  position: absolute;
  width: 0;
  transform: translate(0, 0);
}

.submenu.first.open,
.submenu.second.open {
  position: relative;
  visibility: visible;
  opacity: 1;
  display: block;
  left: 0;
  transform: translate(-13%, 0);
  transition: left 1s ease-in-out 0.9s, opacity 1.5s ease-in-out 0.9s,
    transform 1s ease-in-out, width 1s ease-in-out;
}

.submenu.open .inner-line {
  height: 100%;
  transition: height 1s ease-in-out 1.3s;
}
.inner-line {
  width: 1px;
  height: 0;
  top: 0;
  left: -43px;
  position: absolute;
  background-color: #ffffff52;
}

.hero::after,
.next-page:after {
  content: "";
  background: #00000030;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.hero-inner {
  position: relative;
  display: block;
  z-index: 1;
}

.btn-more {
  background: transparent;
  border-radius: 0;
  padding: 16px 37px;
  position: relative;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  z-index: 0;
  transition: all 0.5s ease;
  display: inline-block;
  min-width: 190px;
  text-align: center;
  color: #fff;
  /* border: 1px solid #fff; */
  margin-top: 20px;
  overflow: hidden;
  position: relative;
}

.btn-more:after {
  content: "";
  width: 0;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: -1;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  transform-origin: left;
  transition: 0.6s;
  transition-timing-function: ease;
}
.btn-more:hover {
  color: #000;
}
.btn-more.dark:hover {
  color: #fff;
}

.btn-more.dark {
  color: #000;
  border: 1px solid #000;
}

:is(.btn-more:hover):after {
  width: 100%;
}
:is(.btn-more.dark:hover):after {
  background-color: #000;
}

#page-title {
  font-size: 50px;
}
.heading2 {
  font-size: 27px;
  line-height: 1em;
  margin-bottom: 0.7em;
  font-weight: 100;
}
.__cont {
  font-weight: 100;
}

/* .is-active .hero {
  background-size: 120%;
  transition: background-size 0.5s ease-in-out 0.9s;
} */

.subtitle_before {
  position: relative;
  background: #ffffff5c;
  width: 1px;
  height: 40px;
  display: block;
}
.hero_descript {
  width: 40%;
}

.darkTheme {
  background-color: #1b1b1e;
  color: #fff;
}

.client {
  min-height: 150px;
}

/* .client img {
  width: auto;
  height: auto;
  max-height: 100px;
  max-width: 220px;
} */
.client img {
    width: auto;
    height: auto;
    max-height: 82px;
    max-width: 158px;
}

/* .splide__list {
  transition: transform 1.6s ease-in-out !important;
} */

.socials {
  position: absolute;
  bottom: 50px;
  right: 0;
  z-index: 10;
  width: 100%;
}
:is(.socials a) {
  margin: 15px;
  font-size: 23px;
  opacity: 0.5;
  color: #fff;
}
:is(.socials a):hover {
  opacity: 1;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.pointer {
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.text-light {
  font-weight: 100;
}

.splide__arrow {
  background: transparent !important;
  border: 0;
}
.splide__arrow svg {
  fill: #fff !important;
  height: 1em;
  width: 1em;
  display: none !important;
}
.splide:hover .splide__arrow svg {
  display: block !important;
}

.splide__slide #page-title,
.splide__slide .__slab,
.splide__slide .hero_descript,
.splide__slide .btn-more {
  opacity: 0;
  left: 10px;
  position: relative;
}

.splide__slide.is-active #page-title {
  opacity: 1;
  left: 0;
  transition: opacity 0.4s ease-in-out, left 0.5s ease-in-out;
}
.splide__slide.is-active .__slab {
  opacity: 1;
  left: 0;
  transition: opacity 0.2s ease-in-out, left 0.3s ease-in-out;
}
.splide__slide.is-active .hero_descript {
  opacity: 1;
  left: 0;
  transition: opacity 0.5s ease-in-out, left 0.6s ease-in-out;
}
.splide__slide.is-active .btn-more {
  opacity: 1;
  left: 0;
  transition: opacity 0.7s ease-in-out 0.3s;
}

.slider-nav {
  background-color: #1a1a1a;
}
.flickity-slider {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
}
.flickity-viewport {
  overflow-x: scroll;
  position: relative;
  height: 100%;
  cursor: grab;
}

.slider-nav li {
  transition: opacity 0.5s;
  padding: 0 20px;
  display: block;
}
.slider-nav li > div {
  height: 300px;
  width: calc(30vw + 2px);
}

ul.splide__pagination {
  display: none !important;
}

/*
 *  STYLE 7
 */

.flickity-viewport::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
  height: 10px;
}

.flickity-viewport::-webkit-scrollbar {
  background-color: #f5f5f5;
  height: 5px;
}

.flickity-viewport::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 10px;
  margin: auto;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-more .bottom,
.btn-more .left,
.btn-more .right,
.btn-more .top {
  position: absolute;
  background-color: #fff;
  background-color: hsla(0, 0%, 100%, 0.5);
}

.btn-more .left {
  left: 0;
  bottom: 0;
  height: 0%;
  width: 1px;
  transition: height 0.7s ease-out;
  transition-delay: 0.1s;
}
.btn-more .top {
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  transition: width 0.7s ease-out;
  transition-delay: 0.7s;
}
.btn-more .right {
  top: 0;
  right: 0;
  height: 0%;
  width: 1px;
  transition: height 0.7s ease-out;
  transition-delay: 1.4s;
}
.btn-more .bottom {
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  transition: width 0.7s ease-out;
  transition-delay: 2s;
}
.splide__slide.is-active .left,
.splide__slide.is-active .right,
.content.is-active .left,
.content.is-active .right {
  height: 100%;
}
.splide__slide.is-active .top,
.splide__slide.is-active .bottom,
.content.is-active .top,
.content.is-active .bottom {
  width: 100%;
}

.next-page {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.next-page.clicked {
  transition: position 0.9s ease-in-out, height 0.9s ease-in-out;
  height: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.content .__slab,
.content #page-title,
.content .hero_descript {
  opacity: 0;
}

.content.is-active .__slab {
  opacity: 1;
  transition: opacity 0.3s ease-in-out 0.3s;
}
.content.is-active #page-title {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.5s;
}
.content.is-active .hero_descript {
  opacity: 1;
  transition: opacity 0.7s ease-in-out 0.8s;
}
.next-page h1 {
  position: relative;
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  font-size: 3.1em;
  opacity: 1;
}

.next-page.clicked h1 {
  opacity: 0;
}

.inner_div {
  content: "";
  background: rgb(27, 27, 30);
  background: linear-gradient(
    357deg,
    rgba(27, 27, 30, 1) 0%,
    rgba(255, 255, 255, 0) 49%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 400px;

  transition: background 0.3s ease-in-out 0.1s;
}

